import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import { Layout } from 'modules/ui';
import { BlogPostsPage, useCategories, BlogSeo } from 'modules/blog';
import type { BlogPost, BlogPostsPageContext, PostCategory } from 'modules/blog/types';

interface DataProps {
    allStrapiPost: {
        nodes: BlogPost[];
    };
    strapiCategory: PostCategory;
}

const BlogPostsCategory = ({ data, pageContext }: PageProps<DataProps, BlogPostsPageContext>) => {
    const categories = useCategories();

    return (
        <>
            <BlogSeo title={data.strapiCategory.title} description="" />
            <Layout>
                <BlogPostsPage
                    title={data.strapiCategory.title}
                    posts={data.allStrapiPost.nodes}
                    categories={categories}
                    basePath={`/blog/category/${data.strapiCategory.slug}`}
                    pageContext={pageContext}
                    showAllCategory
                />
            </Layout>
        </>
    );
};

export const pageQuery = graphql`
    query ($slug: String!, $skip: Int!, $limit: Int!) {
        strapiCategory(slug: { eq: $slug }) {
            ...PostCategory
        }
        allStrapiPost(
            filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
            sort: { fields: publishedAt, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                ...BlogPost
            }
        }
    }
`;

export default BlogPostsCategory;
